
@import "/src/styles/utils/_variables.scss";

.question {

  &-title {
    font-size: 20px;
    line-height: 30px;
    font-weight: 500;
    color: $primary;
  }

  &-subtitle,
  &-content {
    font-size: 14px;
    line-height: 21px;
    color: $gray-3
  }

  &-subtitle-extra {
    font-size: 16px;
    line-height: 24px;
    color: $primary;
    font-weight: 600;

    padding-right: 20%;
    padding-top: 2em;
  }

  &-document-noicon {
    list-style: none;
    margin-bottom: 10px;
    font-style: normal;
  }

}