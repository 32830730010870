[ngbDatepickerDayView] {
  border-radius: 50% !important;
}

.ngb-dp-header {
  background-color: white !important;
}

.ngb-dp-weekday {
  color: #5b5959 !important;
}

.ngb-dp-weekdays {
  border: 0 !important;
  border-radius: .8rem !important;
  background-color: white !important;
}
