@import "/src/styles/base/_colors.scss";

.black {
  font-family: "Raleway-black";
}
.extra-bold {
  font-family: "Raleway-extraBold";
}
.bold {
  font-family: "Raleway-bold";
}
.semi-bold {
  font-family: "Raleway-semiBold";
}
.medium {
  font-family: "Raleway-medium";
}
.regular {
  font-family: "Raleway-normal";
}

.cursive {
  font-style: italic;
}
