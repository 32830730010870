
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@import '~@angular/material/theming';
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat-core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$punku-landing-front-primary: mat-palette($mat-indigo);
$punku-landing-front-accent: mat-palette($mat-pink, A200, A100, A400);

// The warn palette is optional (defaults to red).
$punku-landing-front-warn: mat-palette($mat-red);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$punku-landing-front-theme: mat-light-theme((
  color: (
    primary: $punku-landing-front-primary,
    accent: $punku-landing-front-accent,
    warn: $punku-landing-front-warn,
  )
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include angular-material-theme($punku-landing-front-theme);

/* You can add global styles to this file, and also import other style files */
@charset "UTF-8";

@import "utils/variables";
@import "utils/functions";
@import "utils/mixins";
@import "utils/helpers";

@import "vendors/boostrap";

@import "main";

@import "base/fonts";
@import "base/text";
@import "base/global";
@import "base/display-responsive";
@import "base/gutter";
@import "base/card";
@import "base/accordion";
@import "base/forms";
@import "base/btn";
@import "base/link";
@import "base/slide";
@import "base/tooltip";
@import "base/question";
@import "base/datePicker";
@import "base/colors";
@import "base/swiper-testimonials";
@import "base/quote-form";
@import "base/paginator";
@import "base/radio";

