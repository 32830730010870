* {
  font-family: "Raleway-normal", sans-serif;
}

html {
  scroll-behavior: smooth;
  height: 100%;
}

body {
  height: 100%;
  min-height: 100%;
  font-size: 12px;
  background: #fbfbfb;

  @media (min-width: 600px) {
    font-size: 20px;
  }

  @media (min-width: 800px) {
    font-size: 14px;
  }

  @media (min-width: 1200px) {
    font-size: 16px;
  }
}

P {
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
}
