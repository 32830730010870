.section-pt {
  padding-top: 50px;

  @media (min-width: 960px) {
    padding-top: 45px;
  }
}
.section-pb {
  padding-bottom: 0;

  @media (min-width: 960px) {
    // padding-bottom: 55px;
    padding-bottom: 25px;
  }
}
.section-mt {
  margin-top: 70px;
}
.section-mb {
  margin-bottom: 70px;
}

.ml-lg-50 {
  @media (min-width: 960px) {
    margin-left: 50px;
  }
}