.mat-paginator-container {
  justify-content: center !important;
}
.mat-paginator-page-size {
  display: none !important;
}
.mat-paginator-range-label {
  display: none !important;
}

.mat-custom-page {
  border: none;
  font-weight: 700;
  font-size: 18px;
  color: #4db5ff;

  &:disabled {
    color: #4db5ff !important;
    background-color: white !important;
  }
}
