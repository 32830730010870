/* ACCORDION IN SECTION QUESTIONS - HOME PAGE */
@import "/src/styles/base/_colors.scss";
.av-accordion {
  &__title {
    font-family: "Raleway-semibold";
    font-size: 18px;
    // color: map-get($palette, "dark-2");
  }

  & .card {
    border: none;
    border-radius: 10px !important;
    margin-bottom: 10px;
    background: rgba(255, 255, 255, 0.8) !important;
    box-shadow: 0px 4px 10px rgba(135, 135, 135, 0.15);

    &-header {
      padding: 0;
      background: transparent;
      border: none !important;
      margin: 0 !important;

      & .btn {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        height: 100%;
        padding: 18px 10px;
        font-size: 18px;
        line-height: 30px;
        text-align: left;
        border: none;
        color: map-get($palette, "light-blue-1");
        border-radius: 0;
        // background: transparent;
        font-weight: bold;

        &:after {
          line-height: 18px;
          content: url("../../assets/img/svg/icon-arrow-dropdown.svg");
          transition: 0.2s all ease-in-out;
        }

        &:not(.collapsed) {
          &:after {
            transform: rotate(180deg);
          }
        }

        &.collapsed {
          color: #161616;

          &:after {
            color: map-get($palette, "light-blue-1");
          }
        }

        &:hover,
        &:focus {
          text-decoration: none;
        }

        &:focus {
          box-shadow: none;
        }
      }
    }

    &-body {
      font-size: 14px;
      line-height: 21px;
      padding: 1rem 1.25rem !important;

      & .question-title {
        display: none;
      }
    }
  }

  &.plus {
    & .card {
      & .card-header {
        & .btn {
          &::after {
            padding-top: 0;
            content: url("../../assets/img/plus-icon.png");
          }
          &:not(.collapsed) {
            &:after {
              content: url("../../assets/img/reduce-icon.png");
            }
          }
        }
      }
    }
  }
}
