@import "/src/styles/base/colors";

.link {
  font-style: normal;
  color: map-get($palette, 'light-blue-1');

  &:hover {
    color: map-get($palette, 'light-blue-1');
    text-decoration: none;
  }
}