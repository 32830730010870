.slide {
  .carousel-indicators {
    // display: none;

    // bottom: 1em;
    // li {
    //   width: 15px;
    //   height: 15px;
    //   border-radius: 50%;
    //   background-color: #E8E8E8;

    //   &.active {
    //     background-color: #31DFEA;
    //   }
    // }
  }

  // .carousel-control-next-icon {
  //   background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='aqua' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e");
  // }

  // .carousel-control-prev-icon {
  //   background-image:     url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='aqua' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e")
  // }

  .carousel-control-next,
  .carousel-control-prev {
    z-index: 20 !important;
    width: 2rem !important;
  }
}
