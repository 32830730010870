$primary: #0b3bdf;
$secondary: #33ace0;
$info: #31dfea;
$danger: #e71313;
$success: #4baf55;

$white-1: #ffffff;
$white-2: #e5e5e5;
$white-3: #e9eaf4; // Luego eliminar, no se usará

$gray-1: #e0e0e0;
$gray-2: #acacaa;
$gray-3: #5b5959;
$gray-4: #b3b3b3;

$gradient-1: linear-gradient(96.42deg, #0b3bdf -21.66%, #33ace0 101.53%);
$gradient-2: linear-gradient(96.42deg, #0b3bdf -21.66%, #31dfea 101.53%);
$gradient-3: linear-gradient(96.42deg, #0b3bdf -21.66%, #fc5b1f 101.53%);
$gradient-4: linear-gradient(
  180deg,
  rgba(0, 0, 0, 0.3) 0%,
  rgba(119, 119, 119, 0) 100%
);

$border-radius-info-card: 10px;
$border-radius-product-card: 15px;
$border-radius-element-form: 20px;
$border-radius-banner: 40px;

$shadow-button: 0px 4px 20px #dce2ff;
$shadow-product-form: 0px 4px 4px rgba(180, 180, 180, 0.25);

$theme-colors: (
  "primary": $primary,
  "secondary": $secondary,
  "info": $info,
  "danger": $danger,
);
