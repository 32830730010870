.quote-form {
  max-width: 400px;

  &__actions {
    text-align: center;
  }

  &__btn-new-quote {
    font-size: 15px;
    line-height: 17px;
    text-decoration-line: underline !important;
  }

  .custom-input {
    margin-bottom: 20px;

    &--margin-top {
      margin-top: 30px;
    }

    &__title {
      display: block;
      font-size: 14px;
      color: #5b5959;
    }

    &__description {
      font-size: 12px;
      color: #acacaa;
    }

    .custom-check {
      .custom-check__label {
        font-size: 14px;
        line-height: 1.1;
        color: #5b5959;

        a {
          text-decoration: underline 2px;
        }
      }
    }
  }
}
