body {
  overflow-y: auto;
  overflow-x: hidden;
}

a {
  cursor: pointer;
  color: $gray-3;
  font-size: 14px;

  &:hover {
    color: $gray-3;
    text-decoration: underline;
  }
}

@media (min-width: 1200px) {
  .container-xl,
  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: 1280px;
  }
}

.grecaptcha-badge {
  z-index: 501;

  @media (max-width: 960px) {
    bottom: 54px !important;
  }
}

.no-margin {
  margin: 0;
}

.headroom {
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
}

.loading {
  position: relative;

  &::after {
    content: "";
    position: absolute;
    z-index: 100;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.6);
    pointer-events: stroke;
  }

  app-spinner {
    position: absolute;
    z-index: 101;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.loading-card {
  .card {
    position: relative;

    &::after {
      content: "";
      position: absolute;
      z-index: 100;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(255, 255, 255, 0.6);
      pointer-events: stroke;
    }

    app-spinner {
      position: absolute;
      z-index: 101;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}

.section-home {
  padding-left: 25px;
  padding-right: 25px;

  @media (min-width: 960px) {
    padding-left: 13.38vw;
    padding-right: 13.38vw;
  }
}

.mat-paginator-range-label {
  margin: 0 20px !important;
  min-width: 60px;
  text-align: center;
}

.btn.disabled,
.btn:disabled {
  opacity: 1 !important;
}
