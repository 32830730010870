@import "/src/styles/utils/variables";
@import "/src/styles/base/colors";

$d-top-checkbox: 25%;
$grey-color: #575756;

.form-input {
  min-width: 100%;
  border-radius: 5px;
  height: 48px;
  padding: 10px 17px;
  margin: 0 auto;
  font-size: 13px;
  color: #000000;
  border: 1px solid #e0e0e0;
  box-shadow: none;

  &::placeholder {
    color: map-get($palette, "grey-2");
    font-size: 13px;
  }

  &.is-invalid {
    background-image: none !important;
  }

  // Quitar fondo celeste que son autocompletados en chrome
  &:not(select) {
    &.is-invalid {
      box-shadow: none !important;
    }

    box-shadow: 0 0 0 30px white inset !important;
  }

  &:focus {
    border-color: map-get($palette, "light-blue-1");

    + .form-input__prefix,
    + .form-input__suffix {
      color: map-get($palette, "light-blue-1");
    }
  }

  &.ng-valid {
    + .form-input__prefix,
    + .form-input__suffix {
      color: map-get($palette, "light-blue-1");
    }
  }

  @media (min-width: 960px) {
    &--small {
      max-width: 130px;
      margin: 0;
    }

    &--medium {
      max-width: 200px;
      margin: 0;
    }
  }

  &.is-border-red {
    border-color: #e71313;
  }

  &__aditional {
    font-size: 14px;
    color: map-get($palette, "grey-2");
    margin-left: 0.5rem;
  }

  &__tooltip,
  &__prefix,
  &__suffix {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    font-size: 14px;
    line-height: 1;
    color: #e0e0e0;
  }

  &__tooltip__label {
    position: relative;
    display: inline;
    font-size: 14px;
    line-height: 1;
    color: #e0e0e0;
  }

  &__suffix {
    right: 25px;
  }

  &__prefix {
    left: 25px;
    color: map-get($palette, "light-blue-1");
    padding: 5px 10px 5px 0;
    border-right: 1px solid #dbdbdb;
  }

  &__tooltip {
    right: -30px;
    z-index: 100;
    padding-bottom: 0.15rem;

    & .tooltip.show {
      opacity: 1;
    }

    & .tooltip-inner {
      width: 200px;

      & .title {
        font-weight: 400;
        font-size: 14px;
        line-height: 120%;
      }
    }
  }

  &__tooltip__label {
    right: 0;
    // z-index: 100;

    & .tooltip.show {
      opacity: 1;
    }

    & .tooltip-inner {
      width: 200px;
      min-height: 120px;
      color: white !important;
      background: #4db5ff !important;

      @media (min-width: 960px) {
        width: 290px;
      }

      img {
        width: 100%;
      }

      & .title {
        font-weight: 400;
        font-size: 14px;
        line-height: 120%;
      }
    }

    &.bg-white {
      & .tooltip-inner {
        background: white !important;
      }
    }
  }

  &--has-suffix {
    padding-right: 55px;

    &.is-invalid {
      padding-right: calc(1.5em + 0.75rem + 30px);

      + .form-input__suffix {
        right: 45px;
      }
    }
  }

  &--has-prefix {
    padding-left: 67px;
  }

  &__err {
    display: flex;
    align-items: center;
    height: 12px;
    margin-top: 7px;

    &__icon {
      width: 12px;
      height: auto;
    }

    &__text {
      font-size: 10px;
      line-height: 12px;
      margin-left: 5px;
      color: map-get($palette, "err");
    }
  }
}

.custom-select {
  padding: 0 15px;
  border-radius: 4px;
  height: 40px;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  color: #5b5959;
  border: 1px solid #e0e0e0;
  background-image: url("../../assets/img/arrow_bottom_icon.png");
  background-size: 12px;

  &.is-invalid {
    background: url("../../assets/img/arrow_bottom_icon.png") right 0.75rem
      center/8px 10px no-repeat;
    background-size: 12px;
  }

  // &.is-valid {
  //   background: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 15 11'%3E%3Cpath fill='0B3BDF' d='M6.60569 9.74365L0.325551 3.10303C-0.108517 2.64404 -0.108517 1.90186 0.325551 1.44775L1.36916 0.344238C1.80323 -0.114746 2.50513 -0.114746 2.93458 0.344238L7.38608 5.05127L11.8376 0.344238C12.2717 -0.114746 12.9736 -0.114746 13.403 0.344238L14.4466 1.44775C14.8807 1.90674 14.8807 2.64893 14.4466 3.10303L8.16648 9.74365C7.74165 10.2026 7.03975 10.2026 6.60569 9.74365Z'/%3E%3C/svg%3E")
  //       right 0.75rem center/8px 10px no-repeat;
  // }

  &:focus {
    box-shadow: none !important;
    border-color: map-get($palette, "light-blue-1");
  }
}

// FOR CUSTOM CHECKBOX

.checkbox-custom {
  position: relative;
  padding: 0 0 0 25px;
  margin-bottom: 7px;
  margin-top: 0;
}

.checkbox-custom.custom-check .custom-check__input {
  margin-left: 0;
  width: 20px;
  height: 20px;
}

.checkbox-custom.custom-check .custom-check__label {
  padding-left: 0;

  a {
    text-decoration: underline 2px;
  }
}

.checkbox-custom.checkbox-inline {
  display: inline-block;
  vertical-align: middle;
}

.form-group .checkbox-custom.checkbox-inline {
  padding-top: 0;
}

// .checkbox-custom:last-child,
// .checkbox-custom:last-of-type {
//   margin-bottom: 0;
// }

.checkbox-custom input[type="checkbox"] {
  position: absolute;
  opacity: 0;
  top: $d-top-checkbox;
  left: 0;
  margin: 0;
  z-index: 2;
  cursor: pointer;
  transform: translateY(-$d-top-checkbox);
}

.checkbox-custom input[type="checkbox"]:checked + label:after {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  content: "✔";
  top: $d-top-checkbox;
  left: 0;
  font-size: 13px;
  line-height: 1;
  width: 20px;
  height: 20px;
  color: $white-1;
  transform: translateY(-$d-top-checkbox);
}

.checkbox-custom input[type="checkbox"]:disabled {
  cursor: not-allowed;
}

.checkbox-custom input[type="checkbox"]:disabled:checked + label:after {
  color: $white-1;
}

.checkbox-custom input[type="checkbox"]:disabled + label {
  cursor: not-allowed;
}

.checkbox-custom input[type="checkbox"]:disabled + label:before {
  background-color: $gray-2;
}

.checkbox-custom label {
  // cursor: pointer;
  margin-bottom: 0;
  text-align: left;
  line-height: 1.5;
}

.checkbox-custom label:before {
  content: "";
  position: absolute;
  top: $d-top-checkbox;
  left: 0;
  width: 20px;
  height: 20px;
  display: inline-block;
  border-radius: 3px;
  border: 1px solid $grey-color;
  background: transparent;
  transition: 0.3s all ease-in-out;
  transform: translateY(-$d-top-checkbox);

  @media (min-width: 960px) {
    border: 1px solid map-get($palette, "light-blue-1");
  }
}

.checkbox-custom input[type="checkbox"]:checked:not(:disabled) + label:before {
  background: $grey-color;
  border: none;
}

.checkbox-custom label + label.error {
  display: block;
}

.checkbox-custom.checkbox-custom-transparent label:before {
  background: transparent;
}

.checkbox-custom.checkbox-custom-transparent
  input[type="checkbox"]:checked
  + label:after {
  color: $white-1;
}

.checkbox-custom.checkbox-custom-transparent
  input[type="checkbox"]:disabled:checked
  + label:after {
  color: $gray-2;
}

.custom-check {
  width: 100%;
  text-align: left;
  padding-left: 1.75rem;
}

input[type="date"] {
  &::-webkit-calendar-picker-indicator {
    color: transparent;
    background: none;
    background-image: none;
    z-index: 1;
  }
}

input[type="number"] {
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

.custom-input {
  &__error {
    font-size: 12px;
    line-height: 1.1;
    text-align: left;
    color: $danger;
    padding: 0 1.75rem;
  }
}

textarea {
  &::placeholder {
    color: map-get($palette, "grey-2");
    font-size: 13px;
  }
}
