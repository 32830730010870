
.swiper-button-prev,
.swiper-button-next {
  background: white;
  width: 50px !important;
  height: 50px !important;
  border-radius: 50px;
  box-shadow: -2px 3px 10px rgba(0, 0, 0, 0.12);

  &::after {
    content: '' !important;
    width: 100%;
    height: 100%;
    background: url('../../assets/img/arrow_right_icon.png') no-repeat center center;
  }

  @media (min-width: 960px) {
    top: 90% !important;
  }
}

.swiper-button-prev {
  transform: rotate(180deg);
  @media (min-width: 960px) {
    left: 50% !important;
  }  
}

.swiper-button-next {
  @media (min-width: 960px) {
    right: calc( 50% - 120px ) !important;
  }  
}

.swiper-pagination-bullet-active {
  background: #4DB5FF !important;
  border: none !important;
}