.custom-radio-lg {
  position: relative;

  &__input {
    position: absolute;
    opacity: 0;

    &:checked + .custom-radio-lg__label:before {
      border-color: #4DB5FF;
      background-color: #4DB5FF;
      box-shadow: inset 0 0 0 4px #ffffff;
    }

    &:focus + .custom-radio-lg__label:before {
      outline: none;
      border-color: #4DB5FF;
    }

    &:disabled + .custom-radio-lg__label:before {
      box-shadow: inset 0 0 0 4px #f4f4f4;
      border-color: darken(#f4f4f4, 25%);
      background: darken(#f4f4f4, 25%);
    }
  }

  &__label {
    &:before {
      content: '';
      background: #ffffff;
      border-radius: 100%;
      border: 1px solid #adb5bd;
      display: inline-block;
      width: 1.6em;
      height: 1.6em;
      position: relative;
      margin-right: 1em; 
      vertical-align: top;
      cursor: pointer;
      text-align: center;
      transition: all 250ms ease;
    }

    &:empty {
      &:before {
        margin-right: 0;
      }
    }
  }
}