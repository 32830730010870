@font-face {
  font-family: "Raleway-black";
  src: url("../../assets/font-face/Raleway-Black.woff2") format("woff2"),
    url("../../assets/font-face/Raleway-Black.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Raleway-extraBold";
  src: url("../../assets/font-face/Raleway-ExtraBold.woff2") format("woff2"),
    url("../../assets/font-face/Raleway-ExtraBold.woff") format("woff");
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Raleway-bold";
  src: url("../../assets/font-face/Raleway-Bold.woff2") format("woff2"),
    url("../../assets/font-face/Raleway-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Raleway-semiBold";
  src: url("../../assets/font-face/Raleway-SemiBold.woff2") format("woff2"),
    url("../../assets/font-face/Raleway-SemiBold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Raleway-medium";
  src: url("../../assets/font-face/Raleway-Medium.woff2") format("woff2"),
    url("../../assets/font-face/Raleway-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Raleway-normal";
  src: url("../../assets/font-face/Raleway-Regular.woff2") format("woff2"),
    url("../../assets/font-face/Raleway-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
