.display-mobile {
  @media (min-width: 960px) { 
    display: none !important;
  }
}

.display-desktop {
  @media (max-width: 959px) { 
    display: none !important;
  }
}