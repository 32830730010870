.a-card {
  display: flex;
  flex-flow: column;
  padding: 20px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
  border-radius: $border-radius-product-card;
  border: 2px solid transparent;
  transition: 0.3s all ease-in-out;

  background-color: $white-1;
  color: $gray-3;
  text-align: center;

  &.a-card--hover {
    &:hover {
      border-color: $primary;
    }
  }

  &.a-card--pointer {
    &:hover {
      cursor: pointer;
    }
  }

  &__title {
    line-height: 24px;
    font-size: 16px;
    font-weight: 600;
  }

  &__description {
    flex: 1 1 0;
    line-height: 21px;
    font-size: 14px;
    font-weight: 400;
  }

  &__more {
    font-size: 12px;
    text-decoration: underline;
  }

  &__action {
    .btn {
      z-index: 10;
      padding: 6px 35px;
    }
  }
}
