@import "/src/styles/base/_colors.scss";

.my-custom-class {
  & .tooltip-inner {
    background-color: white;
    border-radius: 11px;
    color: #33ace0;
    text-align: left;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  }
}

.custom-tooltip {
  & .tooltip-inner {
    background-color: white;
    border-radius: 11px;
    width: max-content;
    box-shadow: 0px 1px 7px rgba(0, 0, 0, 0.15);
    border-radius: 5px;
  }
}

.bs-tooltip-right .arrow::before {
  border-right-color: map-get($palette, "light-blue-1");
}
.bs-tooltip-top .arrow::before {
  border-top-color: map-get($palette, "light-blue-1");
}
.bs-tooltip-bottom .arrow::before {
  border-bottom-color: map-get($palette, "light-blue-1");
}
.bs-tooltip-left .arrow::before {
  border-left-color: map-get($palette, "light-blue-1");
}
